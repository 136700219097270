export const BRIDGE_EVENT_EMITTER = 'bridgeEventEmitter';

// known events
export const BRIDGE_EVENTS = {
    IDV: {
        CHANGE_CURRENT_IDV_REPORT: 'CHANGE_CURRENT_IDV_REPORT',
        CHANGE_IDV_REVIEW_TO_UPDATE: 'CHANGE_IDV_REVIEW_TO_UPDATE',
        CHANGE_IDV_REVIEW_TO_UPDATE_DONE: 'CHANGE_IDV_REVIEW_TO_UPDATE_DONE',
    },
};
